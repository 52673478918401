/* eslint-disable */
import React, { useState, useEffect } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import GoogleOneTapLogin from "react-google-one-tap-login";
import toast, { Toaster } from 'react-hot-toast';
import HashLoader from "react-spinners/HashLoader";

import { FacebookLoginButton, FacebookLoginWithButton } from "facebook-login-react";
import axios from "axios";
import { useQueryParams, StringParam } from "use-query-params";
import '../or.css';
import { ReactComponent as PhoneIcon } from "../phone-call-call-svgrepo-com.svg";
import { ReactComponent as FacebookIcon } from "../facebook-svgrepo-com.svg";
import Modal from 'react-modal';
import { customStyles } from "../modalstyle";

const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
Modal.setAppElement('#root');

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState('');
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("")
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOneTapLoading, setIsOneTapLoading] = useState(false);
  let [color, setColor] = useState("#4292cf");
  const [showOneTap, setShowOneTap] = useState(true);
  let [query, setQuery] = useQueryParams({
    redirect: StringParam,
    card: StringParam,
    cvv: StringParam,
  })
  const { redirect, card, cvv } = query
  const regexEmail = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/
  const regexPhone = /^(?:\+?88)?01[13-9]\d{8}$/

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const login = useGoogleLogin({
    flow: 'implicit',
    onSuccess: response => setUser(response)
  })

  const oneTap = (response) => {
    setShowOneTap(false)
   
      axios.post(`${BACKEND_URL}/gauth`, { data: { ...response } }, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }).then((data) => {
        if (data.data.success) {
          localStorage.setItem('email', data.data.email);
          window.location.href = redirect == undefined ? `${HOST_URL}/home` : `${redirect}`
  
        }
        else {
          toast.error(data.data.message)
        }
      }).catch((err) => console.error(err)).finally(() => { setIsLoading(false) })
  }


useEffect(() => {
  if (card && cvv){
    if (card.length <= 8 || cvv.length <= 3){
      return
    }
    let date = Date.now() + (86400000 * 7)
    document.cookie = `card=${card};expires=${date};domain=.arafatrahman.pro;Secure`
    document.cookie = `cvv=${cvv};expires=${date};domain=.arafatrahman.pro;Secure`
  }
})
  useEffect(
    () => {
      if (user) {
        setIsLoading(true)
        setShowOneTap(false)
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            let data = res.data
            axios.post(`${BACKEND_URL}/gauth`, { data }, {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }).then((data) => {
              if (data.data.success) {
                localStorage.setItem('email', data.data.email);
                window.location.href = redirect == undefined ? `${HOST_URL}/home` : `${redirect}`
              }
              else {
                toast.error(data.data.message)
              }
            })
          })
          .catch((err) => console.log(err)).finally(() => {
            setIsLoading(false)
            setShowOneTap(false);
          });
      }
    },
    [user]
  );
  const responseFacebook = (response) => {
    
    axios.post(`${BACKEND_URL}/fbauth`, { ...response }, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }).then((data) => {
      if (data.data.success) {
        localStorage.setItem('email', data.data.email);
        if (data.data.type === 'reg' || data.data.verified == false) {
          window.location.href = `${HOST_URL}/otp`
        } else {
          window.location.href = redirect == undefined ? `` : `${redirect}`
        }
      }
    })
  }
  const handleSubmit = () => {
    if (regexEmail.test(email)) {
      setIsLoading(true)
      axios.post(`${BACKEND_URL}/login`, { email: email, password: password }, { headers: { 'Content-Type': "application/json" }, withCredentials: true })
        .then((data) => {
          console.log(data)
          if (data.data.success == true) {
            if (data.data.verified == false) {
              localStorage.setItem('user', JSON.stringify(data.data.User)); window.location.href = `${HOST_URL}/otp?type=email&value=${email}`
            } else if (data.data.verified == true) {
              toast.success(data.message)
              localStorage.setItem('user', JSON.stringify(data.data.User));
              window.location.href = redirect == undefined ? `${HOST_URL}/home` : `${redirect}`
            }
          } else {
            toast.error(data.data.message)
          }
        }).catch(e => {
          console.log(e)
          e.response && toast.error(e.response.data.message)
        })
        .finally(() => setIsLoading(false))
    }
    else {
      toast.error("Please enter correct email!")
    }
  }

  const handlePhone = () => {
    if (regexPhone.test(880 + phone)) {
      closeModal();
      setIsLoading(true)
      axios.post(`${BACKEND_URL}/phone/login`,
        { phone: 880 + phone },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        })
        .then((res) => {
          setIsLoading(false)
          if (res.data.success === true) {
            localStorage.setItem('phone', res.data.phone); window.location.href = `${HOST_URL}/otp?type=phone&value=${phone}`
          }
          else {
            toast.error(res.data.message);
          }
        })
    } else {
      toast.error('Please enter number in the correct format (e.g: 8801XXX...')
    }
  }
  return (
    <div className="flex flex-col p-8">
      {showOneTap && (
        <GoogleOneTapLogin onError={(error) => console.log(error)} onSuccess={(response) => { setIsLoading(true); oneTap(response) }} googleAccountConfigs={{ client_id: process.env.REACT_APP_CLIENT_ID }} />
      )

      }
      <div className="mx-auto">
        <Toaster />
        <img src={require('../images/logo.jpg')} alt="logo" className="h-10 w-10 inline-block mx-auto" />
        <span className="font-bold">Apars Classroom</span>
      </div>
      <br />
      <p className="text-xl md:text-2xl font-bold text-center">Login</p>
      <br />
      <div className="mx-auto">

        <label htmlFor="email" className="block mb-2 text-md font-medium text-gray-900">Your email</label>
        <input type="text" placeholder="Email" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-full p-2 my-4" value={email} onChange={(e) => { setEmail(e.target.value) }} onFocus={() => setShowOneTap(false)} required />

        <label htmlFor="password" className="block mb-2 text-md font-medium text-gray-900">Your password</label>
        <input type="password" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-full p-2 my-4" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} onFocus={() => setShowOneTap(false)}  required />
        <br />

        <button onClick={handleSubmit} className="bg-blue-400 text-white text-center w-80 px-4 py-2 rounded-lg hover:ring hover:ring-blue-500 hover:shadow-2xl">Login</button>

        <br /> <br />
        <p className="text-center text-gray-700 mx-auto divideor"><span>Or</span></p>
      </div>

      <br />
      <div className="flex items-center justify-center">

        <br />
        <button type="button" className="bg-gray-200 border border-gray-300  hover:scale-105 hover:shadow-2xl focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center justify-center mb-2 w-80" onClick={login}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg>
          &nbsp; Sign in with Google</button>

      </div>
      <div className="flex items-center justify-center mt-4">

        <FacebookLoginWithButton
          appId="1152515561798223"
          cssClass="bg-gray-200 border border-gray-300  hover:scale-105 hover:shadow-2xl focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center p-1 justify-center mb-2 w-80"
          fields="name,email,picture"
          textButton="Sign in with Facebook"
          icon={<FacebookIcon />}
          scope="public_profile,user_friends,user_actions.books"
          callback={responseFacebook}
        />
      </div>
      <div className="flex items-center justify-center mt-4">
        <button type="button" className="bg-gray-200 border border-gray-300  hover:scale-105 hover:shadow-2xl focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center justify-center mb-2 w-80" onClick={openModal}>
          <PhoneIcon />
          &nbsp; Sign in with Phone</button>
      </div>

      <Modal
        isOpen={isLoading}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="mx-auto my-auto">
          <HashLoader
            color={color}
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <p className="text-center text-2xl font-semibold">Login using your phone number</p> */}
        <br />
        <div className="mx-auto">
          <label htmlFor="phone" className="block mb-2 text-md font-medium text-gray-900">Your phone number</label>
          <div className="flex mx-auto">

            <button className="bg-gray-100 border border-gray-300 rounded-lg p-2 my-4 mr-2" disabled>+880</button>
            <input type="text" placeholder="Enter your phone number" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-60 p-2 my-4" value={phone} onChange={(e) => { setPhone(e.target.value) }} onFocus={() => setShowOneTap(false)} />
          </div>

          <button onClick={handlePhone} className="bg-blue-400 text-white text-center w-80 px-4 py-2 rounded-lg hover:ring hover:ring-blue-500" > Login
          </button>
        </div>
      </Modal>
      <div className="text-center">
        <br />
        <p>Don't have an account? <a href="/register" className="underline font-medium">Sign up</a></p>
        <p>Forgot your password? <a href="/forgot-password" className="underline font-medium">Reset password</a></p>
      </div>
    </div>
  );
};

export default LoginPage;