/* eslint-disable */
import React, { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import HashLoader from "react-spinners/HashLoader";
import Modal from 'react-modal';
import { customStyles } from "../modalstyle";

const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const Forget = () => {

  const [email, setEmail] = useState()
  const [isLoading, setIsLoading] = useState(false);
  let [color, setColor] = useState("#4292cf");

  const handleSubmit = () => {
    setIsLoading(true)
    fetch(`${BACKEND_URL}/password-reset`,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email: email }) }).then((response) => response.json())
      .then((res) => {
        setIsLoading(false)
        if (res.success) {
          window.location.href = `${HOST_URL}`
        }
        else {
          toast.error(res.message)
        }
      })
  }

  return (
    <div className="flex flex-col p-8">
       <Toaster />
      <Modal
        isOpen={isLoading}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="mx-auto my-auto font-medium">
          <HashLoader
            color={color}
            className="mx-auto"
            loading={isLoading}
            size={50}
            aria-label="Hash Loader"
            data-testid="loader"
          />
        </div>
      </Modal>

      <div className="mx-auto">
        <img src={require('../images/logo.jpg')} alt="logo" className="h-10 w-10 inline-block mx-auto" />
        <span className="font-bold">Apars Classroom</span>
      </div>
      <br /> <br />
      <div className="m-auto">
        <div className="m-auto">
          <p className="text-center">Forgot Password? Submit your email for a password reset link</p>
          <input type="email" placeholder="Your email" className="mx-auto bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-80 p-2 my-4" value={email} onChange={(e) => { setEmail(e.target.value) }} />

          <div className="text-center"><button onClick={handleSubmit} className=" bg-blue-400 text-center w-80 px-4 py-2 rounded-lg hover:ring hover:ring-blue-500 hover:shadow-2xl">Submit</button></div>
        </div>
      </div>
    </div>
  )
}
export default Forget